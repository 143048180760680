import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Button, VStack, Text, Spinner } from 'native-base';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { fetchData, handleSaveAsDraft, handleCompleteLabeling } from 'src/components/ml/labelingUtils';
import { processImages } from 'src/components/ml/imageUtils';
import Pagination from 'src/components/Pagination';
import NoImagesAvailable from 'src/components/NoImagesAvailable';
import { clearDataset } from 'src/redux/actions/dataset';
import { IStore } from 'src/redux/reducers';
import ImageTable from 'src/components/ml/ImageTable';
import { IImageItem } from 'src/models/UploadImages';

const ITEMS_PER_PAGE = 25;

import { NavigationProp } from '@react-navigation/native';

interface CombinedLabelingPageProps {
    navigation: NavigationProp<any>;
}

const CombinedLabelingPage = ({ navigation }: CombinedLabelingPageProps) => {
    const dispatch = useDispatch();
    const isFocused = useIsFocused();

    const { isJob, datasetId, jobId, images, jobData, jobDetails, isExistingDataset, rejectionMapping, grainId } = useSelector((state: IStore) => ({
        isJob: state.dataset.isJob,
        datasetId: state.dataset.isJob ? state.dataset.datasetID : state.dataset.id,
        jobId: state.dataset.isJob ? state.dataset.id : state.job.jobStatus.job?.jobId,
        images: state.dataset.images,
        jobData: state.job.jobStatus.job,
        jobDetails: state.dataset.jobDetails,
        isExistingDataset: state.dataset.datasets.some(dataset => dataset.datasetID === state.dataset.id),
        rejectionMapping: state.dataset.rejectionMapping,
        grainId: state.grainType.grainId
    }), shallowEqual);

    const [processedImages, setProcessedImages] = useState<IImageItem[]>([]);
    const [changedLabels, setChangedLabels] = useState({});
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [noImagesAvailable, setNoImagesAvailable] = useState(false);

    // Fetch images once and store them in state
    const fetchImagesData = useCallback(async () => {
        try {
            const newImages = await processImages(
                isJob,
                jobData ?? {},
                jobDetails,
                images,
                jobId ?? '',
                datasetId ?? '',
                isExistingDataset,
                isDataFetched
            );

            if (newImages.length > 0) {
                setProcessedImages(newImages);
                setTotalPages(Math.ceil(newImages.length / ITEMS_PER_PAGE));
                setNoImagesAvailable(false);
                setChangedLabels({}); // Reset labels on data fetch
            } else {
                setNoImagesAvailable(true);
            }
        } catch (error) {
            console.error('Error in fetchImagesData:', error);
            setNoImagesAvailable(true);
        }
    }, [isJob, jobData, jobDetails, images, jobId, datasetId, isExistingDataset, isDataFetched]);

    // UseEffect to fetch images only when data is fetched and the component is focused
    useEffect(() => {
        if (isDataFetched && isFocused) {
            fetchImagesData();
        }
    }, [isDataFetched, fetchImagesData, isFocused]);

    // Fetch dataset or job data when focused
    useFocusEffect(
        useCallback(() => {
            if (datasetId || jobId) {
                setIsDataFetched(false);
                fetchData(dispatch, isJob, jobId ?? null, datasetId ?? null, isExistingDataset, setIsDataFetched);
            }
        }, [datasetId, jobId, dispatch, isJob, isExistingDataset])
    );

    // Clear dataset if the component is not focused
    useEffect(() => {
        if (!isFocused) {
            dispatch(clearDataset());
        }
    }, [isFocused, dispatch]);

    // Handle label change without re-downloading images
    const handleLabelChange = useCallback((imagePath: string, newLabel: string) => {
        setChangedLabels((prevState) => ({
          ...prevState,
          [imagePath]: newLabel,
        }));
      }, []);
      

    // Paginate images without fetching again
    const paginatedImages = useMemo(() => {
        return processedImages.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE);
    }, [page, processedImages]);

    if (!isFocused) {
        return null;
    }

    if (!datasetId && !jobId) {
        return <NoImagesAvailable navigation={navigation} />;
    }

    if (noImagesAvailable) {
        return (
            <Box flex={1} justifyContent="center" alignItems="center">
                <Spinner accessibilityLabel="Loading datasets" />
                <Text mt={4}>Loading images...</Text>
            </Box>
        );
    }

    return (
        <Box flex={1} p={5}>
            <VStack space={4} flex={1}>
                <Text fontSize="xl" fontWeight="bold">Label Images</Text>
                <Text>{isJob ? `Job ID: ${jobId}` : `Dataset ID: ${datasetId}`}</Text>
                
                {paginatedImages.length > 0 ? (
                    <ImageTable
                        images={paginatedImages}
                        changedLabels={changedLabels}
                        rejectionMapping={rejectionMapping}
                        onLabelChange={handleLabelChange}
                    />
                ) : (
                    <Text>No images to display on this page.</Text>
                )}

                <Pagination
                    page={page}
                    totalPages={totalPages}
                    onPageChange={setPage}
                />
                
                <Button onPress={() => handleSaveAsDraft(dispatch, isJob, jobId ?? null, datasetId ?? null, grainId ?? '' , changedLabels, processedImages, navigation)} my={2}>
                    Save as Draft
                </Button>
                <Button onPress={() => handleCompleteLabeling(dispatch, isJob, jobId ?? '', datasetId ?? '', grainId ?? '', changedLabels, processedImages, navigation)} my={2}>
                    Complete Labeling
                </Button>
            </VStack>
        </Box>
    );
};

export default CombinedLabelingPage;
